@import "setup.css";

/*
.vo-nav-bar {
	overflow: hidden;
	background-color: #333;

	a {
		float: left;
		display: block;
		color: #f2f2f2;
		text-align: center;
		padding: 14px 16px;
		text-decoration: none;
		font-size: 17px;
	}

	.active {
		background-color: #4CAF50;
		color: white;
	}

	.icon {
		display: none;
	}

	.vo-dropdown,
	.input-group {
		float: left;
		overflow: hidden;
	}

	.input-group {
		padding: 9px 16px;

		.form-control {}
	}

	.vo-dropdown .dropbtn {
		font-size: 17px;
		border: none;
		outline: none;
		color: white;
		padding: 14px 16px;
		background-color: inherit;
		font-family: inherit;
		margin: 0;
	}

	.vo-dropdown-content {
		display: none;
		position: absolute;
		background-color: #f9f9f9;
		min-width: 160px;
		box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
		z-index: 1;
	}

	.vo-dropdown-content a {
		float: none;
		color: black;
		padding: 12px 16px;
		text-decoration: none;
		display: block;
		text-align: left;
	}

	a:hover,
	.vo-dropdown:hover .dropbtn {
		background-color: #555;
		color: white;
	}

	.vo-dropdown-content a:hover {
		background-color: #ddd;
		color: black;
	}


	.vo-dropdown:hover {
		color: red;
	}

	.vo-dropdown:hover .vo-dropdown-content {
		display: block;
	}


	@media screen and (max-width: var(--navbarbreak)) {

		a:not(:first-child),
		.input-group,
		.vo-dropdown .dropbtn {
			display: none;
		}

		a.icon {
			float: right;
			display: block;
		}
	}


	@media (min-width:var(--navbarbreak)) {
		.search {
			width: 150px;
			transition: width .3s ease-in-out;
			-webkit-transition: width .3s ease-in-out;
			-moz-transition: width .3s ease-in-out;
			-ms-transition: width .3s ease-in-out;
		}

		.search:focus-within {
			width: 250px;
		}

		.rightme {
			float: right;
		}

	}



}*/

header.vo-nav-bar {
	padding: 0;
}

.vo-nav-bar {
	border-top: 1px solid #b0b0b0;
	padding: 12px 0;
	width: 100%;



	.main-menu {

		@media (max-width:992px) {
			position: absolute;
			left: 0;
			top: 100%;
		}

		ul {
			padding: 0;
			margin: 0;

			li {
				display: inline-block;
				margin-right: 32px;

				@media (max-width:992px) {
					display: block;
				}

				&:last-child {
					margin-right: 0;
				}

				a {
					color: var(--grey);
					text-transform: uppercase;
					font-weight: 500;



					&:hover {
						color: var(--secondary);
					}
				}
			}
		}

		.btn {
			@media (max-width:340px) {
				font-size: 12px;
				padding: 8px 8px;
			}
		}

		.header-search {
			.form-control {
				height: 32px;
				padding: 0 10px;
				width: 100%;
			}

			.input-group-btn {
				.btn-default {
					height: 32px;
					padding: 0 10px;
				}
			}
		}
	}
}

header {
	background-color: var(--white);
	position: relative;

	@media (max-width:992px) {
		position: fixed;
		width: 100%;
		z-index: 10;
		top: 0;
	}

	.header-middle {
		.container {
			@media (max-width:992px) {
				width: 100%;
			}
		}
	}

	.header-top {
		background-color: var(--secondary);
		padding: 10px;
		text-align: right;
		font-size: 13px;

		@media (max-width:992px) {
			display: none;
		}

		ul {
			padding: 0;
			margin: 0;
			display: inline-block;

			li {
				display: inline-block;
				margin-left: 30px;
				position: relative;

				@media (max-width:992px) {
					display: block;
				}

				a {
					color: var(--white);
					text-decoration: none;
					font-size: 13px;
					letter-spacing: 2px;
				}

				&.fav-count {
					.fa {
						font-size: 16px;
					}

					.sub-count {
						-moz-border-radius: 50%;
						-webkit-border-radius: 50%;
						border-radius: 50%;
						display: block;
						position: absolute;
						top: -6px;
						left: 7px;
						width: 14px;
						height: 14px;
						background: red;
						background: var(--primary);
						color: var(--white);
						text-align: center;
						font-size: 10px;
						line-height: 16px;
					}
				}
			}
		}
	}

	.header-main {
		padding: 12px 0;

		.header-logo {
			display: flex;
			align-items: center;

			@media (max-width:992px) {
				max-width: 120px;
			}


			.logo {
				img {
					max-height: 80px;
				}

				@media (max-width:992px) {
					border-right: none;
					padding-right: 0;
					margin-right: 0;
				}
			}
		}

		.btn-box {
			@media (max-width:992px) {
				border: 1px solid var(--primary);
				padding: 5px 7px;

				display: inline-block;
				margin-right: 5px;
			}

			.fa {
				@media (max-width:992px) {
					font-size: 16px;
				}
			}
		}

		.search-box {
			@media (max-width:992px) {
				background-color: var(--primary);
				color: var(--white);
				font-size: 16px;
				padding: 5px 8px;
			}
		}





		.header-hrs {

			.fa-clock-o {
				@media (max-width:992px) {
					display: none;
				}
			}

			p {
				margin-left: 50px;
				position: relative;
				padding-left: 30px;
				margin-bottom: 0;
				font-weight: 500;

				@media (max-width:992px) {
					margin-left: 20px;
					padding-left: 10px;
				}

				a {
					color: var(--grey);
					font-weight: bold;
				}

				&:first-child {
					margin-left: 0;
				}

				i {
					position: absolute;
					left: 0;
					color: var(--primary);
					font-size: 16px;
					top: 3px;

					@media (max-width:992px) {
						left: -24px;
						font-size: 28px;
						top: 4px;
					}
				}

				span {
					@media (max-width:992px) {
						display: none;
					}
				}
			}
		}
	}
}

.icon {
	display: none;
}



@media screen and (max-width: var(--navbarbreak)) {
	.vo-nav-bar {
		padding: 0;

		.main-menu {
			position: absolute;
			background-color: var(--white);
			padding: 15px;
			z-index: 50;
			display: none;
			width: 100%;

			ul {
				li {
					padding: 5px 0;
					margin-right: 0;
				}
			}
		}

		&.opened {
			.main-menu {
				display: block;
			}
		}
	}

	.vo-nav-bar .icon {
		margin-left: 8px;
		display: inline-block;
		font-size: 22px !important;
		border: 1px solid var(--primary);
		padding: 0 5px;
		text-align: center;
	}

	.icon {
		display: block;
	}

	.vo-nav-bar.opened .input-group {
		display: table;
	}

	.vo-nav-bar.opened .vo-dropdown {
		float: none;
	}

	.vo-nav-bar.opened .vo-dropdown-content {
		position: relative;
	}

	.vo-nav-bar.opened .vo-dropdown .dropbtn {
		display: block;
		width: 100%;
		text-align: left;
	}

}

.sidenav {
	position: fixed;
	top: 210px;
	right: -75px;
	z-index: 5;
	transition: ease .3s;
	-o-transition: ease .3s;

	-webkit-transition: ease .3s;

	@media (max-width:992px) {
		display: none;
	}


	&:hover {
		right: 0;
	}

	.nav-title {
		display: flex;
		color: var(--grey3);
		padding: 10px 12px;
		position: relative;
		background-color: var(--white);
		margin-bottom: 1px;
		box-shadow: 0 0 1px rgba(0, 0, 0, .5) inset;
		height: 92px;
		align-items: center;


		&:hover {
			color: var(--primary);
			text-decoration: none;
		}



		i {
			font-size: 28px;
			margin-right: 12px;
		}
	}
}

@media all and (max-width: 992px) {
	header {
		.header-main {
			.btn-box {
				position: relative;

				.sub-count {
					position: absolute;
					top: -8px;
					right: -8px;
					width: 15px;
					height: 15px;
					background: var(--secondary);
					color: #ffff;
					font-size: 11px;
					display: flex;
					align-items: center;
					justify-content: center;
					border-radius: 100%;
				}
			}
		}
	}
}

@media all and (min-width: 993px) and (max-width: 1199px) {
	.vo-nav-bar {
		.main-menu {
			ul {
				li {
					font-size: 12px;
					margin: 0 15px 0 0;
				}
			}
		}
	}
}