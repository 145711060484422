:root {
	--introImg: url(RESOURCE/img/banner.jpg);
	--introMobileImg: url(RESOURCE/img/banner.Xc_770_770.jpg);
	--navbarbreak: 992px;
	--primary: #FFBF0F;
	--secondary: #009EE3;
	--lightblue: #e9eff6;
	--black: #000000;
	--white: #fff;
	--bodyfont: 'Poppins', sans-serif;
	--grey: #4e4e4e;
	--light-grey: #f5f5f5;
	--darkgrey: #666666;
	--black2: #333333;
	--green: #01bd86;
	--black3: #14141f;
	--grey2: #b7b7b7;
	--red: #ff0000;
	--grey3: #8a8a8a;
	--black4: #484848;
	--darkblue: #0d2e53;
	--black5: #4d4d4d;
	--text_color: #4e4e4e;
	--not_available: #FF6A6B;
	--disabled: #FFF;
	--available: #8DEA5E;
	--selected: #019ee3;
	--selection_range: #019ee3;
	--selected_border: #fff;
}