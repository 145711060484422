.static {
	display: block;
}

.region-view {
	@media (max-width:992px) {
		padding-top: 40px;
	}
}

.ct-form-section {
	.mapnote {
		margin: 0 0 15px 0;
		font-size: 14px;

	}

	.mapconsent {
		background: rgba(26, 121, 187, .15);
		padding: 15px;

		a {
			background: var(--primary);
			color: #fff;
			border-radius: 5px;
			padding: 0 15px;
			height: 36px;
			display: inline-block;
			line-height: 35px;
			margin: 0 0 15px 0;

			&:hover {
				background: var(--secondary);
			}
		}
	}
}

.inner-banner {
	background-image: url(RESOURCE/img/service-banner.jpg);
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
	height: 300px;
	position: relative;

	@media (max-width:992px) {
		height: 300px;
		margin-top: 60px;
	}


	.page-title {
		position: absolute;
		bottom: 70px;
		width: 100%;
		left: 0;
		z-index: 2;
		margin: 0;
		border-bottom: none;

		@media (max-width:992px) {
			bottom: 30px;
		}


		h1 {
			font-size: 42px;
			margin-bottom: 0;
			color: var(--white);
			text-transform: uppercase;

			@media (max-width:992px) {
				font-size: 30px;
			}
		}
	}
}

.about-content {
	.row {
		display: flex;
		align-items: center;
		flex-wrap: wrap;
	}
}

.teams-bl {
	max-width: 1040px;
	margin: 0 auto;
	display: flex;
	flex-wrap: wrap;
	justify-content: center;

	.team-bl {
		.team-dt {
			border: 1px solid #e8e8e8;
			border-top: none;
			padding: 20px 8px;
			text-align: center;
		}
	}
}

.service-content {
	p {
		font-size: 16px;
		line-height: 1.6em;
		margin: 0 0 30px 0;
	}

	h4 {
		margin-bottom: 20px;
		margin-top: 10px;
		background: var(--secondary);
		padding: 5px;
		position: relative;
		display: block;
		color: #fff;
		font-size: 18px;
		line-height: 1.8;
	}

	h5 {
		color: var(--textcolor);
		line-height: 1.8;
		margin: 20px 0 30px 0;
		border-bottom: solid var(--primary);
		position: relative;
		display: block;
		width: max-content;
		font-size: 16px;

		@media (max-width:992px) {
			width: auto;
		}
	}

	p {
		a {
			color: var(--secondary);
		}
	}
}

.inner-page-content {
	p {
		font-size: 16px;
		line-height: 1.6em;
		margin: 0 0 30px 0;

		a {
			color: var(--secondary);
		}

	}
}

@media all and (max-width: 992px) {
	.about-content {
		.col-md-12 {
			padding: 0;
		}
	}
}